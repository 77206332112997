document.addEventListener('DOMContentLoaded', function() {
    const fadeInRightElements = document.querySelectorAll('.fade__in__right');
    const fadeInLeftElements = document.querySelectorAll('.fade__in__left');
    const fadeInRightBottomElements = document.querySelectorAll('.fade__in__right_bottom');
  
    function handleScroll() {
      fadeInRightElements.forEach((element) => {
        if (isElementInViewport(element)) {
          element.classList.add('visible');
        }
      });
      fadeInLeftElements.forEach((element) => {
        if (isElementInViewport(element)) {
          element.classList.add('visible');
        }
      });
      fadeInRightBottomElements.forEach((element, index) => {
        if (isElementInViewport(element)) {
          element.style.transitionDelay = `${index * 0.1}s`; 
          element.classList.add('visible');
        }
      });
    }
  
    function isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top < window.innerHeight && 
        rect.bottom > 0 &&
        rect.left < window.innerWidth && 
        rect.right > 0
      );
    }
  
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
  
    // Trigger scroll event on page load
    handleScroll();
  });
  