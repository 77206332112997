/**
 * This script adds the accessibility-ready responsive menus Genesis Framework child themes.
 *
 * @author StudioPress
 * @link https://github.com/copyblogger/responsive-menus
 * @version 1.1.3
 * @license GPL-2.0+
 */

// noinspection JSValidateJSDoc
// eslint-disable-next-line
(function (document, $, undefined) {
	'use strict';
	const menuToggle = $('.menu-toggle');
	const closeBtn = $('.close-btn');

	menuToggle.on('click', function () {
		$('.mobile-nav').addClass('menu-visible');
        $('.mobile-nav').css('left', '0');

        if($(window).width() <= 620){
            $('.termin-floating-btn').css('display', 'none');
        }
	});
	closeBtn.on('click', function () {
		$('.mobile-nav').removeClass('menu-visible');
        $('.cloned-menu').remove();
	});

	$('.logo-bar').on('click', '.has_childs > a', function(e) {
        e.preventDefault();
    
        // Remove any existing cloned menus
        if($('.cloned-menu').length){
            $clonedNav.css({
                'opacity': '1',
                'background-color': randomColor,
                'left': '0'
            });
        }
        
        
        // Define your color array and select a random color
        const colors = ['#E3DACE', '#F3EEE8', '#FBF9F7'];
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
    
        // Clone the navigation menu and append it to the header wrapper
        const $clonedNav = $('.mobile-nav').clone();
        $clonedNav.addClass('cloned-menu').appendTo('.header-wrap');
    
        // Hide all items in the cloned navigation
        $clonedNav.find('.menu-item').css('display', 'none');
    
        // Extract the specific class for targeting
        const itemClass = $(this).parent().attr('class').split(/\s+/)[2];
        const targetItem = $clonedNav.find('.' + itemClass);
    
        // Hide all other `.has_childs` elements
        $clonedNav.find('.parent-item').css('display', 'none');
        $clonedNav.find('.termin-wrapper').css('display', 'none');
    
        // Now show the parent `.has_childs` of the clicked item and the target item itself
        $(this).parent().css('display', 'block');
        targetItem.css('display', 'block').addClass('submenu-visible');
        
        $clonedNav.addClass('menu-visible');
        setTimeout(function() {
            $('.mobile-nav').removeClass('menu-visible');
            $('.mobile-nav').css('left', '-100%');
            $('.mobile-nav').css('top', '-20%!important');
            $clonedNav.css({
                'opacity': '1',
                'background-color': randomColor,
                'left': '0'
            });
            $clonedNav.find('a span').addClass('rotate-arrow');
            $('.rotate-arrow:after').css('rotate', '90deg');
        }, 5);

        $clonedNav.on('click', '.has_childs > a', function(e) {
            e.preventDefault();
            $('.mobile-nav').addClass('menu-visible').animate({
                left: '0',
                top: '0',
                opacity: '1'
            }, 500, function() { // Adjust the duration as needed (500ms here)
                // Add rotate-arrow class and set the CSS rotate property
                $clonedNav.find('a span').removeClass('rotate-arrow');
                $('.rotate-arrow:after').css('rotate', '90deg');
                setTimeout(function() {
                    // Remove the cloned menu after the timeout
                    $clonedNav.remove();
                }, 1000); // Adjust the timeout duration as needed (50ms here)
            });
        });
        
    });

    

    
    $('.logo-bar').on('click', '.close-btn', function() {
        if ($('.cloned-menu').length) {
            $('.mobile-nav').css('left', '0');
            setTimeout(function() {
               $('.cloned-menu').animate({
                left: '-100%' 
            }, 500, function() {  
                $(this).remove();
            });
            }, 10);
            
        } 
        $('.mobile-nav').css('left', '-100%');
            $('.mobile-nav').removeClass('menu-visible');
        if($(window).width() <= 620){
            $('.termin-floating-btn').css('display', 'flex');
        }
    });


})(document, jQuery);
