/**
 * Gets image elements and displays them as carousel on mobile
 *
 * @version 1.1.3
 * @license GPL-2.0+
 */
(function (document, $, undefined) {
    'use strict';

    $(document).ready(function () {
        function initializeCarousel() {
            var $carouselContainers = $('.carousel-mobile');
            var imageSrcs = [];
            // Collect all image srcs from all .carousel-mobile elements
            $carouselContainers.find('img').each(function () {
                imageSrcs.push($(this).attr('src'));
            });
            // Only proceed if there are images to display
            if (imageSrcs.length > 0) {
                var $newCarousel = $('<div class="start-carousel-mobile"></div>');
                if ($(window).width() <= 780) {
                $.each(imageSrcs, function (index, src) {
                    $newCarousel.append('<div><img src="' + src + '" alt="Carousel Image ' + (index + 1) + '"></div>');
                });
            
                // Append new carousel structure to the body or a specific container
                // $carouselContainers.last().after($newCarousel);
                $('.carousel-container').append($newCarousel);
                }
                // Initialize Slick carousel if the screen width is 780px or less
                function initSlick() {
                    if ($(window).width() <= 780) {
                        if (!$newCarousel.hasClass('slick-initialized')) {
                            $newCarousel.slick({
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                arrows: true,
                                dots: false,
                                autoplay: true,    
                                autoplaySpeed: 1700
                            });
                        }
                    } else {
                        if ($newCarousel.hasClass('slick-initialized')) {
                            $newCarousel.slick('unslick');
                        }
                    }
                }
                initSlick();
                $(window).on('resize', initSlick);
            }
        }

        // Initialize carousel on load
        initializeCarousel();
    });
})(document, jQuery);
