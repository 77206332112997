/**
 * Converts Gutenberg columns to a carousel on mobile screens
 *
 * @version 1.1.3
 * @license GPL-2.0+
 */
(function (document, $, undefined) {
    'use strict';

    $(document).ready(function () {
        function initializeCarousel() {
            var $carouselContainer = $('.carousel-container');
            var $carouselColumns = $('.gutenberg-columns .gutenberg-column');
            var $newCarousel = $('<div class="columns-carousel-mobile"></div>');

            // Move each column's content into the new carousel structure
            $carouselColumns.each(function () {
                var $columnContent = $(this).html();
                $newCarousel.append('<div class="slide">' + $columnContent + '</div>');
            });

            // Append new carousel structure to the carousel container
            $carouselContainer.append($newCarousel);

            // Initialize Slick carousel if the screen width is 780px or less
            function initSlick() {
                if ($(window).width() <= 780) {
                    if (!$newCarousel.hasClass('slick-initialized')) {
                        $newCarousel.slick({
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: true
                        });
                    }
                    // Hide original columns
                    $('.gutenberg-columns').hide();
                } else {
                    if ($newCarousel.hasClass('slick-initialized')) {
                        $newCarousel.slick('unslick');
                    }
                    // Show original columns
                    $('.gutenberg-columns').show();
                    // Remove the new carousel structure to reset
                    $newCarousel.empty();
                }
            }

            initSlick();
            $(window).on('resize', initSlick);
        }

        // Initialize carousel on load
        initializeCarousel();
    });
})(document, jQuery);
